#media-gallery {
  height: 28rem;
}

#description > p {
  margin-bottom: 1rem;
}

#details-table dl {
  height: 300px;
}

@media screen and (max-width: 768px) {
  #media-gallery {
    height: 232px;
  }
  #details-table dl {
    height: 272px;
  }
}